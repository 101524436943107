<template>
  <div>
    <div class="relative table-responsive table-r">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-table
        ref="refBasicTable"
        :items="gridData || []"
        :fields="columns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        no-local-sorting
        show-empty
        :foot-clone="!!footer"
        class="basic-table"
      >
        <template #empty>
          <div class="text-center">
            Нет данных
          </div>
        </template>
        <template #cell(id)="data">
          <div>
            <div
              v-if="data.field.expand"
              class="d-flex align-items-center"
            >
              <BButton
                variant="flat-primary"
                class="mr-2 btn-icon rounded-circle"
                size="sm"
                @click="rowClicked(data.item)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  :class="{ rotate: data.item._showDetails }"
                />
              </BButton>
              <span>
                {{ data.item.id }}
              </span>
            </div>
            <div v-else>
              <span>
                {{ data.item.id }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(id_invoice)="data">
          <div>
            <div
              v-if="data.field.expand"
              class="d-flex align-items-center"
            >
              <BButton
                variant="flat-primary"
                class="mr-2 btn-icon rounded-circle"
                size="sm"
                @click="rowClicked(data.item, data.item.id_invoice)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  :class="{ rotate: data.item._showDetails }"
                />
              </BButton>
              <span>
                {{ data.item.id_invoice }}
              </span>
            </div>
            <div v-else>
              <span>
                {{ data.item.id_invoice }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(Id_Ord)="data">
          <div>
            <div
              v-if="data.field.expand"
              class="d-flex align-items-center"
            >
              <BButton
                variant="flat-primary"
                class="mr-2 btn-icon rounded-circle"
                size="sm"
                @click="rowClicked(data.item)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  :class="{ rotate: data.item._showDetails }"
                />
              </BButton>
              <span>
                {{ data.item.Id_Ord }}
              </span>
            </div>
            <div v-else>
              <span>
                {{ data.item.Id_Ord }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(refer)="data">
          <div>
            <div
              v-if="data.field.expand"
              class="d-flex align-items-center"
            >
              <BButton
                variant="flat-primary"
                class="mr-2 btn-icon rounded-circle"
                size="sm"
                @click="rowClicked(data.item)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  :class="{ rotate: data.item._showDetails }"
                />
              </BButton>
              <span>
                <span v-html="data.item.refer" />
              </span>
            </div>
            <span v-else>
              <span v-html="data.item.refer" />
            </span>
          </div>
        </template>
        <template #row-details="row">
          <b-card
            no-body
            class="mb-0"
          >
            <b-tabs
              v-if="row.fields[0] && row.fields[0].expand && row.fields[0].expand.tabs"
              content
            >
              <b-tab
                v-for="(tab, key) in row.fields[0].expand.tabs"
                :key="key"
                :active="key === 0"
              >
                <template #title>
                  {{ tab.title }}
                </template>
                <inner-table
                  v-if="tab.content === 'table'"
                  :items="[]"
                  :columns="tab.columns"
                  :api="tab.api"
                  :api-id="row.item.id || row.item.id_invoice"
                  :hide-pagination="tab.hidePagination"
                />
                <div
                  v-if="tab.content === 'html'"
                  class="p-1"
                >
                  <html-content
                    :api="tab.api"
                    :api-id="row.item.id"
                  />
                </div>
              </b-tab>
            </b-tabs>
            <div
              v-if="row.fields[0] && row.fields[0].expand && row.fields[0].expand.div"
              class="p-2"
            >
              <div
                v-for="(div, key) in row.fields[0].expand.div"
                :key="key"
              >
                <html-content
                  v-if="div.content === 'html'"
                  :api="div.api"
                  :api-id="row.item[row.fields[0].expand.id_key]"
                />
              </div>
            </div>
          </b-card>
        </template>
        <template #cell(FIO)="data">
          <div>
            <div
              v-if="data.field.expand"
              class="d-flex align-items-center"
            >
              <BButton
                variant="flat-primary"
                class="mr-2 btn-icon rounded-circle"
                size="sm"
                @click="rowClicked(data.item)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  :class="{ rotate: data.item._showDetails }"
                />
              </BButton>
              <div>
                <b-media
                  v-if="data.item.avatar"
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.avatar.src && `${server + data.item.avatar.src}`"
                      :text="avatarText(data.item.FIO.replace(/[0-9]/g, ''))"
                      :variant="data.item.avatar.color"
                    />
                  </template>
                  <span v-html="data.item.FIO" />
                </b-media>
                <span
                  v-else
                  v-html="data.item.FIO"
                />
              </div>
            </div>
            <div v-else>
              <b-media
                v-if="data.item.avatar"
                vertical-align="center"
              >
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.avatar.src && `${server + data.item.avatar.src}`"
                    :text="avatarText(data.item.FIO.replace(/[0-9]/g, ''))"
                    :variant="data.item.avatar.color"
                  />
                </template>
                <span v-html="data.item.FIO" />
              </b-media>
              <span
                v-else
                v-html="data.item.FIO"
              />
            </div>
          </div>
        </template>
        <template #cell(inv_Date)="data">
          <span v-html="data.item.inv_Date" />
        </template>
        <template #cell(BB_date)="data">
          <span v-html="data.item.BB_date" />
        </template>
        <template #cell(BB_hint)="data">
          <span v-html="data.item.BB_hint" />
        </template>
        <template #cell(strStatus)="data">
          <span v-html="data.item.strStatus" />
        </template>
        <template #cell(iPayStatus)="data">
          <div>
            <feather-icon v-if="data.item.iPayStatus" icon="XCircleIcon" />
            <feather-icon v-else icon="CheckCircleIcon" />
          </div>
        </template>
        <template #cell(Buyer)="data">
          <span v-html="data.item.Buyer" />
        </template>
        <template #cell(cont_DiscountStr)="data">
          <span v-html="data.item.cont_DiscountStr" />
        </template>
        <template #cell(h_date)="data">
          <span v-html="data.item.h_date" />
        </template>
        <template #cell(Payment)="data">
          <span v-html="data.item.Payment" />
        </template>
        <template #cell(inpt_name)="data">
          <span
            class="d-inline-flex align-items-center"
            v-html="data.item.inpt_name"
          />
        </template>
        <template #cell(Status)="data">
          <span v-html="data.item.Status" />
        </template>
        <template #cell(Ord_Status)="data">
          <span v-html="data.item.Ord_Status" />
        </template>
        <template #cell(bp_sType)="data">
          <span v-html="data.item.bp_sType" />
        </template>
        <template #cell(status)="data">
          <span v-html="data.item.status" />
        </template>
        <template #cell(BB_sType)="data">
          <span v-html="data.item.BB_sType" />
        </template>
        <template #cell(Descr)="data">
          <div v-html="data.item.Descr" />
        </template>
        <template #cell(bp_Comment)="data">
          <div v-html="data.item.bp_Comment" />
        </template>
        <template #cell(Ord_Comment)="data">
          <div v-html="data.item.Ord_Comment" />
        </template>
        <template #cell(BB_Comment)="data">
          <div v-html="data.item.BB_Comment" />
        </template>
        <template #cell(h_Descr)="data">
          <div v-html="data.item.h_Descr" />
        </template>
        <template #cell(BB_icon)="data">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="data.item.BB_hint"
            variant="flat-info"
          >
            <i :class="data.item.BB_icon" />
          </b-button>
        </template>
        <template #cell(bp_icon)="data">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="data.item.bp_hint"
            variant="flat-info"
          >
            <i :class="data.item.bp_icon" />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.field.actions && data.field.actions.length"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div
              v-for="(action, key) in data.field.actions"
              :key="key"
            >
              <b-dropdown-item
                v-if="(!data.item.Actions || !action.name) || (data.item.Actions && data.item.Actions.indexOf(action.name) > -1)"
                @click="execAction({ ...action, apiId: data.item[action.id_key], item: data.item })"
                :disabled="disabled(action.disabled, data)"
              >
                <feather-icon :icon="action.icon" />
                <span class="align-middle ml-50">{{ action.label.replace('{' + action.id_key + '}', data.item[action.id_key]) }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>

        <template
          v-if="footer"
            #foot(Balance)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(Bonus)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(Descript)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(FIO)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(IsPaymentRO)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(Itog_Sum)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(Penny)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(SumInRub)
          >
          <h5>{{ footer.SumInRub }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(Town_Name)
          >
          <h5>{{ footer.Town_Name }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(commision)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(id)
          >
          <h5>{{ footer.id }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(num)
          >
          <h5>{{ footer.num }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(prod_name)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(col)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(Prod_PriceCost)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(cost_bal)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(realCol)
          >
          <span></span>
        </template>
        <template
          v-if="footer"
            #foot(SumPriceRub)
          >
          <h5>{{ footer.SumPriceRub }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(prod_articul)
          >
          <h5>{{ footer.prod_articul }}</h5>
        </template>
        <template
          v-if="footer"
            #foot(sum_bal)
          >
          <h5>{{ footer.sum_bal }}</h5>
        </template>
      </b-table>
    </div>
    <b-row
      v-if="!hidePagination"
      class="mt-2"
    >

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, BOverlay,
  BButton, VBTooltip, BTabs, BTab, BCard,
  BDropdown, BDropdownItem, BMedia, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import HtmlContent from '@core/components/html-content/HtmlContent.vue'
import basicTableStoreModule from './basicTableStoreModule'
import store from '@/store'
import useBasicTable from './useBasicTable'
import InnerTable from './InnerTable.vue'

export default {
  name: 'BasicTable',
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BButton,
    BTabs,
    BTab,
    BCard,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    InnerTable,
    HtmlContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
    modal: {
      type: Boolean,
      required: false,
    },
    selectedItem: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    xparam1: {
      type: [Object, String, null],
      required: false,
    },
    xparam2: {
      type: [Object, String, null],
      required: false,
    },
    xparam3: {
      type: [Boolean, Object, String, null],
      required: false,
      default: () => undefined,
    },
    xparam4: {
      type: [Object, String, null],
      required: false,
    },
    zz2Period: {
      type: [Object, String, null],
      required: false,
    },
    zz3periodFrom: {
      type: [Object, String, null],
      required: false,
    },
    zz4periodTo: {
      type: [Object, String, null],
      required: false,
    },
    zz6isPack: {
      type: [Object, String, null],
      required: false,
    },
    zz1search: {
      type: [Object, String, null],
      required: false,
    },
    id_buyer: {
      type: [Object, String, null],
      required: false,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'basic-table'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, basicTableStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // eslint-disable-next-line
    const disabled = (val, data) => {
      // eslint-disable-next-line
      return eval(val)
    }

    const {
      refBasicTable,
      gridData,
      footer,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,
      sortBy,
      isSortDirDesc,

      fetchData,
      rowClicked,
      execAction,
    } = useBasicTable(props, emit)

    if (props.api && (props.autoload || props.loadByTab === null)) {
      fetchData()
    }

    const server = process.env.VUE_APP_SERVER

    return {
      server,
      refBasicTable,
      gridData,
      footer,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,
      sortBy,
      isSortDirDesc,

      rowClicked,
      execAction,

      avatarText,
      disabled,
    }
  },
}
</script>
<style scoped>
  .responsive {
    width: 100%;
    /* overflow: auto; */
  }
  .rotate {
    transform: rotate3d(0,0,1,90deg);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .orders-table >>> .b-table-details td:first-child {
    padding-top: 0;
  }
  .orders-table >>> .b-table-empty-row td:first-child {
    padding-top: 0.72rem;
  }
  .table-r {
    min-height: 320px;
  }
  .table-r >>> .table {
    color: #0b0b0b;
  }

  .basic-table >>> tfoot th {
    text-align: right!important;
  }
</style>
