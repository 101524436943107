<template>
  <div>
    <div class="responsive relative">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-table
        ref="refBasicTable"
        :items="gridData || []"
        :fields="columns"
        show-empty
      >
        <template #empty>
          <div class="text-center">
            Нет данных
          </div>
        </template>
        <template #cell(inv_Date)="data">
          <span v-html="data.item.inv_Date" />
        </template>
        <template #cell(h_date)="data">
          <span v-html="data.item.h_date" />
        </template>
        <template #cell(Payment)="data">
          <span v-html="data.item.Payment" />
        </template>
        <template #cell(inpt_name)="data">
          <span
            class="d-inline-flex align-items-center"
            v-html="data.item.inpt_name"
          />
        </template>
        <template #cell(Status)="data">
          <span v-html="data.item.Status" />
        </template>
        <template #cell(bp_sType)="data">
          <span v-html="data.item.bp_sType" />
        </template>
        <template #cell(status)="data">
          <span v-html="data.item.status" />
        </template>
        <template #cell(Descr)="data">
          <div v-html="data.item.Descr" />
        </template>
        <template #cell(bp_Comment)="data">
          <div v-html="data.item.bp_Comment" />
        </template>
        <template #cell(BB_Comment)="data">
          <div v-html="data.item.BB_Comment" />
        </template>
        <template #cell(h_Descr)="data">
          <div v-html="data.item.h_Descr" />
        </template>
        <template #cell(BB_icon)="data">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="data.item.BB_hint"
            variant="flat-info"
          >
            <i :class="data.item.BB_icon" />
          </b-button>
        </template>
        <template #cell(bp_icon)="data">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="data.item.bp_hint"
            variant="flat-info"
          >
            <i :class="data.item.bp_icon" />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.field.actions && data.field.actions.length"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div
              v-for="(action, key) in data.field.actions"
              :key="key"
            >
              <b-dropdown-item
                v-if="(!data.item.Actions || !action.name) || (data.item.Actions && data.item.Actions.indexOf(action.name) > -1)"
                @click="execAction({ ...action, apiId: data.item.id })"
              >
                <feather-icon :icon="action.icon" />
                <span class="align-middle ml-50">{{ action.label.replace('{id}', data.item.id) }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <b-row
      v-if="!hidePagination"
      class="mt-2"
    >

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, BOverlay,
  BButton, VBTooltip,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useInnerTable from './useInnerTable'

export default {
  name: 'BasicTable',
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    xparam1: {
      type: [Object, String, null],
      required: false,
    },
    xparam2: {
      type: [Object, String, null],
      required: false,
    },
    xparam3: {
      type: [Object, String, null],
      required: false,
    },
    xparam4: {
      type: [Object, String, null],
      required: false,
    },
    id_buyer: {
      type: [Object, String, null],
      required: false,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
  },
  setup(props) {
    const {
      refBasicTable,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,

      fetchData,
      rowClicked,
      execAction,
    } = useInnerTable(props)

    if (props.api && (props.autoload || props.loadByTab === null)) {
      fetchData()
    }

    const server = process.env.VUE_APP_SERVER

    return {
      server,
      refBasicTable,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,

      rowClicked,
      execAction,

      avatarText,
    }
  },
}
</script>
<style scoped>
  .responsive {
    width: 100%;
    overflow: auto;
  }
  .rotate {
    transform: rotate3d(0,0,1,90deg);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .orders-table >>> .b-table-details td:first-child {
    padding-top: 0;
  }
  .orders-table >>> .b-table-empty-row td:first-child {
    padding-top: 0.72rem;
  }
</style>
