import {
  ref, computed, watch, set,
} from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export default function useBasicTable(props, emit) {
  const refBasicTable = ref(null)
  const gridData = ref(null)
  const loading = ref(false)
  const total = ref(0)
  const footer = ref(null)
  const currentPage = ref(1)
  const perPage = ref(25)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = gridData.value ? gridData.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const fetchData = () => {
    loading.value = true
    // eslint-disable-next-line prefer-destructuring
    const xparam1 = props.xparam1
    // eslint-disable-next-line prefer-destructuring
    const xparam2 = props.xparam2
    // eslint-disable-next-line prefer-destructuring
    const xparam3 = props.xparam3
    // eslint-disable-next-line prefer-destructuring
    const xparam4 = props.xparam4
    // eslint-disable-next-line prefer-destructuring
    const zz2Period = props.zz2Period
    // eslint-disable-next-line prefer-destructuring
    const zz3periodFrom = props.zz3periodFrom
    // eslint-disable-next-line
    const zz4periodTo = props.zz4periodTo
    // eslint-disable-next-line
    const zz6isPack = props.zz6isPack
    // eslint-disable-next-line
    const zz1search = props.zz1search
    // eslint-disable-next-line
    const id_buyer = props.id_buyer
    return store.dispatch('basic-table/fetchData', {
      api: props.api,
      apiId: props.apiId,
      params: {
        xparam1,
        xparam2,
        xparam3,
        xparam4,
        zz2Period,
        zz3periodFrom,
        zz4periodTo,
        zz6isPack,
        zz1search,
        // eslint-disable-next-line
        id_buyer,
        sortby: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        zpage: currentPage.value,
        zstart: perPage.value * (currentPage.value - 1),
        zlimit: perPage.value,
      },
    })
      .then(response => {
        const { data } = response.data
        if (!Array.isArray(data)) {
          gridData.value = data.rows
          total.value = data.paging[0].total || 0
          footer.value = data.paging[0].footer || null
        } else {
          gridData.value = data
          total.value = data.length

          const footIndex = data.findIndex(item => item.lineType === 'footer')

          if (footIndex > -1) {
            footer.value = data[footIndex]
            gridData.value.splice(footIndex, 1)
          }
        }

        loading.value = false
        return response
      })
      .catch(err => {
        gridData.value = null
        total.value = 0
        loading.value = false
        return err
      })
  }

  const rowClicked = item => {
    // eslint-disable-next-line no-underscore-dangle
    if (!item._showDetails) {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    } else {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    }
  }

  const execAction = action => {
    if (action.name === 'PrintOrd') {
      const decodedToken = useJwt.getDecodedToken()

      const params = {
        id_sess: decodedToken.id_sess,
        id_user: decodedToken.uid,
        xparam1: action.apiId,
        toFileHeader1: `Заказ №: ${action.item.Id_Ord}`,
        toFileHeader2: `дата: ${action.item.Ord_StDate} статус: ${action.item.strStatus.replace(/(<([^>]+)>)/gi, '')}`,
        toFileName: `goodOrder${action.item.Id_Ord}_`,
        ...action.params,
      }

      window.open(`${action.api}?${new URLSearchParams(JSON.parse(JSON.stringify(params))).toString()}`)

      return
    }

    if (action.name === 'Print') {
      const decodedToken = useJwt.getDecodedToken()

      window.open(`${action.api}?id_sess=${decodedToken.id_sess}&id_buyer=${decodedToken.uid}&${action.print_key}=${action.apiId}`)

      return
    }

    if (action.name === 'openModal') {
      emit('update:modal', true)
      emit('update:selectedItem', action)
      return
    }

    store.dispatch('basic-table/execAction', {
      ...action,
      alert: action.alert === undefined || false,
    }).then(res => {
      if (res) {
        fetchData()
      }
    })
  }

  watch(() => props.apiId, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.xparam1, () => {
    fetchData()
  })

  watch(() => props.xparam2, () => {
    fetchData()
  })

  watch(() => props.xparam3, () => {
    fetchData()
  })

  watch(() => props.xparam4, () => {
    fetchData()
  })

  watch(() => props.zz2Period, () => {
    fetchData()
  })

  watch(() => props.zz3periodFrom, () => {
    fetchData()
  })

  watch(() => props.zz4periodTo, () => {
    fetchData()
  })

  watch(() => props.zz6isPack, () => {
    fetchData()
  })

  watch(() => props.zz1search, () => {
    fetchData()
  })

  // eslint-disable-next-line
  watch(() => props.id_buyer, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.currentTab, () => {
    if (props.currentTab === props.loadByTab) {
      fetchData()
    }
  })

  watch([currentPage, sortBy, isSortDirDesc], () => {
    fetchData()
  })

  watch(() => props.refresh, () => {
    if (props.refresh) {
      fetchData()
    }
  })

  return {
    refBasicTable,
    gridData,
    loading,
    total,
    footer,
    currentPage,
    perPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,

    fetchData,
    rowClicked,
    execAction,
  }
}
