import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useSelectInput(props) {
  const opts = ref([])

  if (props.options) {
    opts.value = JSON.parse(JSON.stringify(props.options))
  }

  const fetchData = () => {
    // eslint-disable-next-line prefer-destructuring
    const xparam1 = props.xparam1
    // eslint-disable-next-line prefer-destructuring
    const apiId = props.apiId

    store.dispatch('select-input/fetchData', {
      ...props,
      apiId,
      params: {
        xparam1,
      },
    })
      .then(response => {
        const { data } = response.data
        opts.value = data
      })
      .catch(() => {
        opts.value = []
      })
  }

  watch(() => props.apiId, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.currentTab, () => {
    if (props.currentTab === props.loadByTab) {
      fetchData()
    }
  })

  watch(() => props.xparam1, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  return {
    opts,
    fetchData,
  }
}
